
import { Vue, Component, Watch } from 'vue-property-decorator'
import { SearchBar, SearchToken } from '@/components/search-bar'
import { NavigationTabs, SearchTabs } from '@/components/navigation-tabs'
import { JournalResults } from '@/components/search-results/journal-results'
import { SearchParams } from '@/global-types'
import { updateRouter } from '@/components/search-results/journal-results/utils'
import { updateSearchParams } from '@/utils/utils'
import { JournalSuggestions } from '@/components/serials-suggestions'

@Component({
  components: {
    NavigationTabs,
    SearchBar,
    JournalResults,
    JournalSuggestions,
  },
})
export default class JournalSearch extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  searchText = ''
  searchParams: SearchParams = {
    query: '',
    pageNumber: 1,
    pageSize: 20,
    sortBy: 'relevance',
    orderBy: 'asc',
    facets: '',
  }

  readonly journalTab = SearchTabs.journal
  readonly searchFields: SearchToken[] = [
    { name: 'Journal Code', value: 'jc', internal: true, description: 'A 5-digit code for the journal', example: 'DUKMJ for Duke Mathematical Journal' },
    { name: 'Journal Abbreviation', value: 'st' },
    { name: 'Journal Name', value: 'j', format: 'journal', description: 'Name of the journal' },
    { name: 'ISSN', value: 'issn' },
    // { name: 'Journal EISSN', value: 'eissn' },
    { name: 'Publisher', value: 'p' },
  ]

  readonly searchOperators: SearchToken[] = [
    { name: 'logical AND', value: 'AND', example: 'a AND b' },
    { name: 'logical OR', value: 'OR', example: 'a OR b' },
    { name: 'logical NOT', value: 'NOT', example: 'NOT a' },
  ]

  created() {
    const localPageSize = localStorage.getItem(this.$route.name + 'PageSize')
    if (localPageSize) {
      this.searchParams.pageSize = parseInt(localPageSize)
    }
    this.updateDataFromURLParams()
  }

  async mounted() {
    document.title = 'Journal Search - MathSciNet'
  }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    document.title = 'Journal Search - MathSciNet'
    this.updateDataFromURLParams()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  // get searchText() {
  //   return this.$store.state.SearchTextModule.journalsSearchText
  // }

  // set searchText(value) {
  //   this.$store.commit(UPDATE_JOURNALS_SEARCH_TEXT, value)
  // }

  updateDataFromURLParams() {
    this.searchParams = updateSearchParams(this.$route, this.searchParams)
    if (this.searchParams.query) {
      this.searchText = this.searchParams.query
    }
  }

  async search(searchText: string) {
    if (searchText.trim().length > 0) {
      this.searchParams.pageNumber = 1
      this.$nextTick(() => {
        updateRouter(this.$router)({ ...this.searchParams, query: '' })
        updateRouter(this.$router)({ ...this.searchParams, query: searchText, pageNumber: 1 })
      })
    }
  }

  clear() {
    this.searchText = ''
    this.searchParams.query = ''
    this.$route.query.query = ''
  }
}
